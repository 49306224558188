import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../containers/about"

type AboutPageProps = {}

const AboutPage: React.FunctionComponent<AboutPageProps> = (props: any) => {
    const {data} = props
    return (
        <Layout>
            <SEO
                title="About Us" slug={`about`}
                description={data.site.siteMetadata.description}
            />

            <About/>
        </Layout>
    )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        tagLine
      }
    }
  }
`